import { container, title } from '../CoreStyles';

const  adminSignUpStyles = {
  container,
  test:{
    height: "300px"
  }
};

export default adminSignUpStyles;
