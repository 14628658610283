/* @flow */

// THREEJS
import * as THREE from 'three';

// utils
import { base64ImageToBlob } from './image';

// constants
import { MAP_TYPES, THREE_MESH } from '../constants/application';

import lodash from "lodash";

export function volumeFromBounds(bbox: typeof THREE.Box3): Object {

  let { min, max } = bbox;
  let shape = {};
  shape.h = max.y - min.y;
  shape.w = max.x - min.x;
  shape.l = max.z - min.z;
  shape.volume = shape.l * shape.w * shape.h;
  return shape;

}

export function fitBoxes(bbox1: typeof THREE.Box3, bbox2: typeof THREE.Box3, factor: number): number {

  let shape1 = volumeFromBounds(bbox1);
  let shape2 = volumeFromBounds(bbox2);
  let size1 = Math.floor(shape1.h * shape1.w);
  let size2 = Math.floor(shape2.h * shape2.w);
  let fit = size1 % (size2 / factor);
  return fit;

}

type Materials = THREE.MeshStandardMaterial | THREE.MeshPhongMaterial | THREE.MeshLambertMaterial;

export function mapMaterials(materials: Array<Materials> , callback): Array<Materials> {
  if (materials.constructor === Array) return materials.map(material => callback(material));
  return callback(materials);
}

export function getMaterials(mesh) {
  const materials = [];
  mesh.traverse((m) => {
    if (m.constructor.name === THREE_MESH) {
      materials.push(m.material)
    }
  });
  return lodash.uniqBy(materials.reduce((a, b) => a.concat(b), []), "uuid");
}

export function traverseMaterials(mesh, callback) {
  mesh.traverse((child) => {
    if (child.constructor.name === THREE_MESH) {
      if (child.material) {
        child.material = callback(child.material);
        child.material.needsUpdate = true;
      }
    }
  });
}

export function exportMap(material: Materials): Array<Object> {
  let images = [];
  if (material.map !== null) {
    if (material.map.image !== undefined) {
      let imageData = base64ImageToBlob(material.map.image.currentSrc, 1024);
      images.push({ rawData: imageData.rawData, ext: imageData.ext, type: MAP_TYPES.DIFFUSE_MAP });
    }
  }
  if (material.normalMap !== null) {
    if (material.normalMap.image !== undefined) {
      let imageData = base64ImageToBlob(material.normalMap.image.currentSrc, 1024);
      images.push({ rawData: imageData.rawData, ext: imageData.ext, type: MAP_TYPES.NORMAL_MAP });
    }
  }
  return images;
}

export function getExtension(path: string): string {
  return '.' + path.split('.').pop();
}

function getVertex(arr, idx) {
  const vertex = new THREE.Vector3();
  vertex.x = arr[idx];
  vertex.y = arr[idx+1];
  vertex.z = arr[idx+2];
  return vertex;
}

export function getFaceCentroid(geometry, face) {
  const vertices = geometry.attributes.position.array;
  const a = getVertex(vertices, face.a);
  const b = getVertex(vertices, face.b);
  const c = getVertex(vertices, face.c);
  const centroid = new THREE.Vector3();
  centroid.x = (a.x + b.x + c.x) / 3;
  centroid.y = (a.y + b.y + c.y) / 3;
  centroid.x = (a.z + b.z + c.z) / 3;
  return centroid;
}
